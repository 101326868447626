'use client';

import { useRouter } from 'next/navigation';
import Navbar from '@/components/navbar/Navbar';
import { Button } from '@/components/button';
import { Cards, CardBody, CardHeader } from '@/components/card';
import Footer from '@/components/footer';
import ExploreProducts from '@/components/home/ExploreProducts';
import { IoStar, IoStarHalf } from 'react-icons/io5';
import { FiChevronRight } from 'react-icons/fi';
import { useEffect, useState } from 'react';
import { useHomePage } from '@/stores/homepage/homepage';
import Link from 'next/link';
import { useAllBlogs } from '@/stores/all-blog';

// geo-location
import { useGeolocation } from '@uidotdev/usehooks';
import { Container } from '@/components/container';
import Image from 'next/image';

export default function Home() {
  const geoLocation = useGeolocation();
  const router = useRouter();
  const {
    blogData,
    titleSection,
    getHomepageData,
    homepageData,
    productShowcase,
    productCategoryShowcase,
    sectionHero,
  } = useHomePage();

  const [selectedCategory, setCategory] = useState('');
  const { blogs, getBlogs } = useAllBlogs((state) => ({
    blogs: state.blogs,
    getBlogs: state.getBlogs,
  }));
  useEffect(() => {
    getHomepageData();
  }, [getHomepageData]);

  useEffect(() => {
    getBlogs(1, 1000);
  }, [getBlogs]);

  useEffect(() => {
    document.title = 'Homepage - MRCOOL';
  }, [homepageData]);

  const blogLatest = blogs
    ?.sort(
      (a: any, b: any) =>
        new Date(b.attributes.date).getTime() -
        new Date(a.attributes.date).getTime()
    )
    .slice(0, 3)
    .map((data) => data);

  return (
    <main className='flex min-h-screen w-full flex-col overflow-hidden'>
      {/* navbar */}
      <Navbar className='bg-white' />

      {/* section-1 */}
      <section className='py-14 lg:py-24'>
        <div className='mx-auto w-full max-w-[90rem] px-5 lg:px-24 xl:px-[7.5rem]'>
          <div className='flex flex-col gap-9 md:gap-16'>
            <div className='flex w-full flex-col items-center justify-center gap-4 text-center'>
              <h1 className='text-4xl font-bold md:text-7xl'>
                {titleSection ? titleSection.title : ''}
              </h1>
              <p className='text-lg md:text-xl'>
                {titleSection ? titleSection.subtitle : ''}
              </p>
            </div>
            <div className='grid grid-cols-2 gap-4 sm:gap-6 md:px-10 lg:grid-cols-4'>
              {productShowcase &&
                productShowcase.map((data) => {
                  return (
                    <Link
                      key={data.id}
                      href={`/product/${data?.attributes?.slug}`}
                      title={data.attributes.name}
                      className='group relative h-auto max-h-[220px] min-h-44 w-full rounded-xl hover:cursor-pointer'
                    >
                      <Cards className='group relative h-full w-full rounded-xl hover:cursor-pointer'>
                        <CardBody className='relative  my-auto flex h-full w-full flex-col items-center px-4 py-6'>
                          {data.attributes.new_release && (
                            <span className='absolute left-0 z-[1] mr-auto rounded-full border border-blue-400 bg-blue-400 px-2 py-0.5 text-xs text-white'>
                              New
                            </span>
                          )}

                          {data.attributes &&
                          data.attributes.thumbnail &&
                          data.attributes.thumbnail.data ? (
                            <img
                              className='mb-2 h-[135px] w-auto rounded-xl  object-cover duration-300 group-hover:scale-110'
                              src={
                                data.attributes.thumbnail.data.attributes.url
                              }
                              alt={
                                data.attributes.thumbnail.data.attributes
                                  .caption ?? ''
                              }
                            />
                          ) : (
                            ''
                          )}

                          <p className='text-center text-base font-medium'>
                            {data.attributes.name}
                          </p>
                        </CardBody>
                      </Cards>
                    </Link>
                  );
                })}
            </div>
          </div>
        </div>
      </section>

      {/* section-2 */}
      <section className='py-5'>
        <div className='mx-auto w-full max-w-[90rem] px-5'>
          {sectionHero ? (
            <div
              className='flex h-[26.25rem] w-full overflow-hidden rounded-[1.25rem] bg-cover bg-local bg-center object-cover p-4 sm:min-h-[680px] sm:p-6 md:h-full md:rounded-3xl'
              style={{
                backgroundImage: `url('${sectionHero.image ? sectionHero.image.data?.[0]?.attributes.url : ''}')`,
              }}
            >
              <div className='m-auto flex h-full w-full flex-col justify-center gap-6  p-6 text-center font-medium text-gray-900'>
                <div className='flex flex-col gap-5'>
                  <h5 className='text-base font-medium sm:text-lg'>
                    {sectionHero.title}
                  </h5>
                  <h1 className='text-center text-4xl font-bold sm:text-5xl lg:text-8xl'>
                    {sectionHero.description}
                  </h1>
                </div>
                <div className='flex flex-col md:mt-16'>
                  {/* <p className="text-lg sm:text-xl font-normal">
                From $1,738 for single zone. As low as $2,666 for multi zone.
              </p> */}

                  <Link href={`product/${sectionHero.link}`}>
                    <Button
                      variant='link'
                      className='gap-x-1 text-base transition duration-150'
                    >
                      <span>{sectionHero.link_label ?? ''}</span>
                      <FiChevronRight className='h-5 w-5 text-inherit' />
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </section>

      {/* section-3 */}
      <section className='py-14 lg:py-24'>
        <div className='mx-auto w-full max-w-[90rem] px-5 lg:px-24 xl:px-[7.5rem]'>
          {productCategoryShowcase ? (
            <ExploreProducts ProductCategory={productCategoryShowcase} />
          ) : (
            ''
          )}
        </div>
      </section>

      {/* section-4 */}
      <section className='py-14 lg:py-24'>
        <div className='mx-auto w-full max-w-[90rem] px-5 lg:px-24 xl:px-[7.5rem]'>
          <div className='mx-auto mb-10 flex w-full flex-col gap-9 text-center sm:mb-16 lg:w-[640px]'>
            <h2 className='text-center text-4xl font-bold lg:text-5xl'>
              Join thousands enjoying their space in comfort.
            </h2>
            <div className='flex flex-col gap-2 text-center text-lg sm:text-xl'>
              <p>Over 1,560 verified customer reviews.</p>
              <div className='flex items-center justify-center gap-2'>
                <IoStar className='h-4 w-4 text-primary' />
                <IoStar className='h-4 w-4 text-primary' />
                <IoStar className='h-4 w-4 text-primary' />
                <IoStar className='h-4 w-4 text-primary' />
                <IoStarHalf className='h-4 w-4 text-primary' />
              </div>
            </div>
          </div>

          <div className='grid w-full'>
            {/* <div className='w-full sm:col-span-2'>
            <div className='grid grid-cols-2 gap-3 md:grid-cols-2  lg:grid-cols-2'>
              <img
                className='h-full max-h-[11.563rem] w-full  rounded-xl object-cover object-center duration-300 sm:max-h-[21.25rem]'
                src='https://res.cloudinary.com/dbkbadq4w/image/upload/v1718899366/hp_act_01_3b1e94de8c.webp'
                alt='Image Description'
              />

              <img
                className='h-full max-h-[11.563rem] w-full rounded-xl object-cover object-center duration-300 sm:max-h-[21.25rem]'
                src='https://res.cloudinary.com/dbkbadq4w/image/upload/v1718899366/hp_act_02_315df82ed3.webp'
                alt='Image Description'
              />

              <img
                className='col-span-2 h-full max-h-[11.563rem] w-full rounded-xl object-cover object-center duration-300 sm:max-h-[21.25rem]'
                src='https://res.cloudinary.com/dbkbadq4w/image/upload/v1718899366/hp_act_03_67b783e026.webp'
                alt='Image Description'
              />
            </div>
          </div> */}
            <img
              className='hidden h-full w-full rounded-3xl object-cover object-center duration-300 md:block'
              src='https://res.cloudinary.com/dbkbadq4w/image/upload/v1718901201/hp_act_bundle_01_3424c4b6ca.webp'
              alt='Image Description'
            />
            {/* Responsive */}
            <img
              className='h-full w-full rounded-3xl object-cover object-center duration-300 sm:max-h-none md:hidden'
              src='https://res.cloudinary.com/dbkbadq4w/image/upload/v1718901216/hp_act_bundle_01_mob_7fbb9b8993.webp'
              alt='Image Description'
            />
          </div>
        </div>
      </section>

      {/* section-5 */}
      <section className='py-14 lg:py-24'>
        <div className='mx-auto w-full max-w-[90rem] px-5 lg:px-24 xl:px-[7.5rem]'>
          <div className='grid w-full grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6'>
            <Cards className='full flex w-full flex-col gap-6 rounded-3xl bg-gray-50 p-9 sm:gap-6'>
              <CardHeader className='text-base font-medium'>
                Where can I buy?
              </CardHeader>
              <CardBody className='flex flex-col gap-6'>
                <h3 className='text-3xl font-bold leading-tight lg:text-4xl'>
                  Explore nearby dealers and get the product you love.
                </h3>
                <Link href={{ pathname: '/locations' }} scroll={true}>
                  <Button
                    variant='custom'
                    color='primary'
                    className='inline-flex w-full max-w-max rounded-full bg-primary px-6 py-[10px] text-base text-white hover:bg-primary-dark hover:text-white'
                  >
                    Find a dealer
                  </Button>
                </Link>
              </CardBody>
            </Cards>

            <Cards className='flex h-full w-full flex-col gap-3 rounded-3xl bg-gray-50 p-9 sm:gap-6'>
              <CardHeader className='text-base font-medium'>
                Become a reseller
              </CardHeader>
              <CardBody className='flex flex-col gap-6'>
                <h3 className='text-3xl font-bold leading-tight lg:text-4xl'>
                  Join our robust ecosystem and expand your opportunities.
                </h3>
                <div className='flex w-full items-center gap-x-3'>
                  {/* <Link href={{ pathname: "/support/become-reseller" }}>
                    <Button variant='link' color='primary' className='text-base gap-x-1 transition duration-150'>
                      <span>Learn more</span>
                      <FiChevronRight className='h-5 w-5 text-inherit' />
                    </Button>
                  </Link> */}
                  <Link href={{ pathname: '/support/become-reseller' }}>
                    <Button
                      variant='custom'
                      color='primary'
                      className='inline-flex w-full max-w-max rounded-full bg-primary px-6 py-[10px] text-base text-white transition duration-150 hover:bg-primary-dark hover:text-white'
                    >
                      Register now
                    </Button>
                  </Link>
                </div>
              </CardBody>
            </Cards>

            <Cards className='flex h-full w-full flex-col gap-3 rounded-3xl bg-gray-50 p-9 sm:gap-6'>
              <CardHeader className='text-base font-medium'>
                Rebate center
              </CardHeader>
              <CardBody className='flex flex-col gap-6'>
                <div className='text-3xl font-bold leading-tight lg:text-4xl'>
                  Maximize savings. Enjoy additional benefits.
                </div>
                <Link href={{ pathname: '/rebates' }}>
                  <Button
                    variant='custom'
                    color='primary'
                    className='inline-flex w-full max-w-max rounded-full bg-primary px-6 py-[10px] text-base text-white transition duration-150 hover:bg-primary-dark hover:text-white'
                  >
                    Visit Rebate Center
                  </Button>
                </Link>
              </CardBody>
            </Cards>

            <Cards className='flex h-full w-full flex-col gap-3 rounded-3xl bg-gray-50 p-9 sm:gap-6'>
              <CardHeader className='text-base font-medium'>
                Influencer program
              </CardHeader>
              <CardBody className='flex flex-col gap-6'>
                <div className='text-3xl font-bold leading-tight lg:text-4xl'>
                  Connect with us through the MRCOOL® Influencer Program.
                </div>
                <div className='flex w-full items-center gap-x-3'>
                  {/* <Link href={{ pathname: "/support/become-influencer" }}>
                    <Button variant='link' color='primary' className='text-base gap-x-1 transition duration-150'>
                      <span>Learn more</span>
                      <FiChevronRight className='h-5 w-5 text-inherit' />
                    </Button>
                  </Link> */}
                  <Link
                    href='/support/become-influencer'
                    as='/support/become-influencer#apply-influencer'
                  >
                    <Button
                      variant='custom'
                      color='primary'
                      className='inline-flex w-full max-w-max rounded-full bg-primary px-6 py-[10px] text-base text-white transition duration-150 hover:bg-primary-dark hover:text-white'
                    >
                      Become an influencer
                    </Button>
                  </Link>
                </div>
              </CardBody>
            </Cards>
          </div>
        </div>
      </section>

      {/* section-6 */}
      <section className='py-5'>
        <div className='mx-auto w-full max-w-[90rem] px-5'>
          <div className='grid h-full w-full grid-cols-1 overflow-hidden rounded-3xl lg:min-h-[620px] lg:grid-cols-3'>
            <div
              className='h-[284px] w-full bg-cover bg-center sm:col-span-2 lg:h-full'
              style={{
                backgroundImage:
                  "url('https://res.cloudinary.com/dbkbadq4w/image/upload/v1718904871/hp_story_01_f1a35515ba.webp')",
              }}
            ></div>
            <div className='flex h-full w-full flex-col flex-col justify-between bg-primary px-5 py-7 text-white sm:px-9 sm:py-14'>
              <div className='text-2xl font-semibold md:text-xl lg:text-4xl'>
                We at MRCOOL® are led by a drive for progress and innovation,
                and it&rsquo;s taken us to some pretty cool places.
              </div>
              {/* <Button
              variant="custom"
              className="mt-auto inline-flex max-w-max rounded-full text-primary hover:text-primary-dark bg-gray-100 py-1 px-3"
            >
              <span>About us</span>
            </Button> */}
              <Link
                href={{ pathname: 'our-story' }}
                className='mt-auto inline-flex items-center gap-2 text-base text-white hover:text-white lg:text-base'
              >
                <Button
                  variant='link'
                  className='mt-8 inline-flex items-center gap-x-1 text-base text-white hover:text-white lg:mt-auto lg:text-base'
                >
                  <span>Learn more about our story</span>
                  <FiChevronRight className='h-5 w-5 text-inherit' />
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* section 7 */}
      <section className='py-14 lg:py-24'>
        <div className='mx-auto w-full max-w-[90rem] px-5 lg:px-24 xl:px-[7.5rem]'>
          <div className='mb-10 flex w-full flex-1 sm:mb-16'>
            <h2 className='text-3xl font-bold sm:text-5xl'>
              Stay informed and in touch.
            </h2>
          </div>

          <div className='grid w-full grid-cols-1 gap-6 md:grid-cols-3'>
            {blogLatest.map((data) => {
              return (
                <div
                  key={data.id}
                  className='flex w-full flex-col gap-2 sm:gap-5'
                >
                  <Link href={{ pathname: `/blog/${data.attributes.slug}` }}>
                    <div className='h-[220px] lg:h-[280px]'>
                      {data.attributes.thumbnail?.data?.attributes.url ? (
                        <Image
                          width={363}
                          height={280}
                          className='h-full w-full rounded-2xl object-cover'
                          src={data.attributes.thumbnail?.data?.attributes.url}
                          alt={
                            data.attributes.thumbnail?.data?.attributes
                              .caption ?? ''
                          }
                        />
                      ) : (
                        <Container className='rounded-2xl' />
                      )}
                    </div>
                    <div className='mt-6 flex flex-col justify-between md:grow md:gap-3'>
                      <h4 className='my-3 text-lg font-bold leading-tight text-zinc-900 lg:text-2xl lg:leading-snug'>
                        {data.attributes.title}
                      </h4>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <Footer />
    </main>
  );
}
