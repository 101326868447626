import { create } from 'zustand';
import AxiosInstance, { AxiosRequestConfig } from 'axios';
import type {
  BlogHomepage,
  Data,
  Product,
  ProductCategoryShowcase,
  ProductDetailData,
  Section,
} from '@/types/homepage/HomepageApiResponse';
import qs from 'qs';

const axios = AxiosInstance.create({
  baseURL: process.env.API_ENDPOINT,
});

interface HomepageState {
  homepageData: Data | null;
  blogData: BlogHomepage[] | null;
  productShowcase: ProductDetailData[];
  productCategoryShowcase: ProductCategoryShowcase[] | null;
  isLoading: boolean;
  isError: boolean;
  titleSection: Section | null;
  sectionHero: Section | null;
  sectionProduct: Section | any;
  getHomepageData: () => void;
  getHomepageBlog: () => void;
}

export const useHomePage = create<HomepageState>((set) => ({
  homepageData: null,
  blogData: null,
  productShowcase: [],
  productCategoryShowcase: [],
  isLoading: false,
  isError: false,
  titleSection: null,
  sectionHero: null,
  sectionProduct: null,
  getHomepageData: async () => {
    try {
      const queryHomepage = {
        populate: {
          sections: { populate: '*' },
          product_category_showcase: {
            populate: {
              thumbnail: '*',
              hero: '*',
              products: {
                populate: ['thumbnail'],
              },
            },
          },
          product_showcase: {
            populate: ['thumbnail'],
          },
        },
      };
      const urlParams = qs.stringify(queryHomepage);
      set({ isLoading: true });
      const { data: res } = await axios.get(`/page/homepage?${urlParams}`);
      const title = res.data.attributes.sections.find(
        (data: Section) => data.__component === 'pages.section-title'
      );
      const product_showcase = res.data.attributes.product_showcase.data.map(
        (data: ProductDetailData) => data
      );
      const product_category_showcase =
        res.data.attributes.product_category_showcase.data.map(
          (data: ProductCategoryShowcase) => data
        );
      const section_hero = res.data.attributes.sections.find(
        (data: Section) => data.__component === 'pages.section-hero'
      );
      const section_productInfo = res.data.attributes.sections.find(
        (data: Section) => data.__component === 'products.info'
      );
      set({ titleSection: title });
      set({ homepageData: res.data });
      set({ productShowcase: product_showcase });
      set({ sectionHero: section_hero });
      set({ sectionProduct: section_productInfo });
      set({ productCategoryShowcase: product_category_showcase });
    } catch (error) {
      console.error(error);
      set({ isError: true });
    } finally {
      set({ isLoading: false });
    }
  },
  getHomepageBlog: async () => {
    try {
      const queryBlog = {
        populate: '*',
        pagination: {
          page: 1,
          pageSize: 3,
        },
      };
      set({ isLoading: true });
      const urlParams = qs.stringify(queryBlog);
      const { data: res } = await axios.get(`/blogs?${urlParams}`);
      set({ blogData: res.data });
    } catch (error) {
      set({ isError: true });
    } finally {
      set({ isLoading: false });
    }
  },
}));
