'use client';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CardBody, Cards } from '@/components/card';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button } from '@/components/button';
// import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import { MdArrowForwardIos, MdArrowBackIos } from 'react-icons/md';
import type { ProductCategoryShowcase } from '@/types/homepage/HomepageApiResponse';
import { useHomePage } from '@/stores/homepage/homepage';
import Link from 'next/link';

export interface PropsData {
  ProductCategory: ProductCategoryShowcase[];
}

const ExploreProducts = (props: PropsData) => {
  const sliderRef = useRef<any>(null);
  const [tabActive, setTabActive] = useState('ductless-mini-split');
  const [befChange, setBefChange] = useState<number>(0);

  const [categoryData, setCategoryData] =
    useState<ProductCategoryShowcase | null>();
  const { sectionProduct } = useHomePage();
  useEffect(() => {
    const categories = props.ProductCategory.find(
      (data) => data.attributes.slug === tabActive
    );
    if (categories) {
      setCategoryData(categories);
    } else {
      setCategoryData(null);
    }
  }, [tabActive, props.ProductCategory]);

  const numProducts = categoryData?.attributes.products?.data.length ?? 0;
  const beforeChange = (prev: any, next: any) => {
    setBefChange(next);
  };
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: numProducts > 5 ? 5 : numProducts,
    className: 'slider variable-width',
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    autoplaySpeed: 500,
    cssEase: 'ease-in-out',
    arrows: false,
    variableWidth: numProducts <= 2 ? true : false,
    beforeChange(currentSlide: any, nextSlide: any) {
      beforeChange(currentSlide, nextSlide);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: numProducts > 3 ? 3 : numProducts,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  };

  const renderArrows = () => {
    return (
      <div className='z-10 block h-full w-full'>
        <Button
          disabled={befChange === 0}
          variant='custom'
          className={`prev absolute left-0 top-[45%] z-10 rounded-full bg-white p-4 shadow ${befChange === 0 && 'bg-[#ededed69]'}`}
          onClick={() => sliderRef?.current?.slickPrev()}
        >
          <MdArrowBackIos />
        </Button>
        <Button
          disabled={
            categoryData?.attributes?.products?.data &&
            befChange === categoryData?.attributes?.products?.data.length - 5
          }
          variant='custom'
          className={`next absolute right-0 top-[45%] z-10 rounded-full bg-white p-4 shadow ${categoryData?.attributes?.products?.data && befChange === categoryData?.attributes?.products?.data.length - 5 && 'bg-[#ededed69]'}`}
          onClick={() => sliderRef?.current?.slickNext()}
        >
          <MdArrowForwardIos />
        </Button>
      </div>
    );
  };

  return (
    <div className='flex w-full flex-col gap-5'>
      <h1 className='text-center text-4xl font-bold text-gray-900 sm:text-6xl'>
        {sectionProduct?.name}
      </h1>

      <div className='mb-9'>
        <nav className='hide-scrollbar -mb-0.5 flex space-x-6 overflow-auto sm:justify-center'>
          {props.ProductCategory?.map((data) => {
            return (
              <button
                key={data.id}
                type='button'
                className={`
                ${data.attributes.slug === tabActive ? 'border-gray-900 text-gray-900' : 'border-transparent'}
                inline-flex items-center gap-x-2 whitespace-nowrap border-b-2 px-1 py-4 text-base font-medium 
                text-gray-500 hover:text-gray-900 
                focus:text-gray-900 focus:outline-none
                disabled:pointer-events-none disabled:opacity-50
                  `}
                onClick={() => setTabActive(data.attributes.slug)}
              >
                {data.attributes.name ?? ''}
              </button>
            );
          })}
        </nav>
      </div>

      {categoryData ? (
        <div className='mt-3'>
          <div className='grid h-auto w-full grid-cols-1 overflow-hidden rounded-3xl bg-gray-100 sm:h-[560px] sm:grid-cols-2 '>
            <div className='flex w-full flex-col gap-5 p-9 font-light lg:p-14'>
              <h5 className='text-base font-medium sm:text-lg'>
                {categoryData?.attributes.name}
              </h5>
              <h3 className='text-3xl font-bold md:text-4xl lg:mt-9'>
                {categoryData?.attributes.tagline}
              </h3>
              <p className='text-lg sm:text-xl'>
                {categoryData?.attributes.description}
              </p>
            </div>

            {/* cards */}
            <Cards className='relative h-auto min-h-44 w-full'>
              <CardBody className='relative my-auto flex h-full w-full flex-col items-center overflow-hidden'>
                {categoryData?.attributes.hero?.data?.attributes.url && (
                  <img
                    className='h-full w-full object-contain duration-300 sm:m-auto sm:object-cover'
                    src={categoryData?.attributes.hero?.data?.attributes.url}
                    alt={
                      categoryData?.attributes.hero?.data?.attributes.caption ??
                      ''
                    }
                  />
                )}
              </CardBody>
            </Cards>
          </div>

          {/* product carousel */}
          {categoryData?.attributes.products?.data.length ? (
            <div className='slider-container relative p-5'>
              {renderArrows()}
              {/* <pre>
                  {JSON.stringify(categoryData.attributes.products.data)}
                </pre> */}
              {/* <Slider ref={sliderRef} {...settings}> */}
              {categoryData.attributes.products.data.length > 1 ? (
                <Slider ref={sliderRef} {...settings}>
                  {categoryData.attributes.products.data.map((item) => {
                    return (
                      <Link
                        key={item.id}
                        href={`/product/${item?.attributes?.slug}`}
                        title={item.attributes.name}
                        onClick={() => console.log('onclick-card')}
                        style={{ width: 180, height: 180 }}
                      >
                        <Cards className='group relative h-auto min-h-16 w-full max-w-48 rounded-xl hover:cursor-pointer sm:min-h-48'>
                          <CardBody className='relative my-auto flex h-32 w-full flex-col items-center overflow-hidden sm:h-44 sm:px-4 sm:py-4'>
                            {item.attributes.new_release && (
                              <span className='absolute left-0 top-0 z-[1] mr-auto rounded-full border border-blue-400 bg-blue-400 px-2 py-0.5 text-xs text-white sm:top-2'>
                                New
                              </span>
                            )}
                            <img
                              className='my-auto h-auto max-h-[80px] w-auto rounded-xl object-cover duration-300 group-hover:scale-110 sm:h-full sm:max-h-[180px]'
                              src={
                                item.attributes.thumbnail?.data?.attributes.url
                              }
                              alt={
                                item.attributes.thumbnail?.data?.attributes
                                  .caption ?? ''
                              }
                            />
                            <p className='h-full w-full text-center text-sm font-medium sm:inline-block'>
                              {item.attributes.name}
                            </p>
                          </CardBody>
                        </Cards>
                      </Link>
                    );
                  })}
                </Slider>
              ) : (
                <>
                  {categoryData.attributes.products.data.map((item) => {
                    return (
                      <div key={item.id} className='w-[180px]'>
                        <Link
                          href={`/product/${item?.attributes?.slug}`}
                          title={item.attributes.name}
                          onClick={() => console.log('onclick-card')}
                        >
                          <Cards
                            key={item.id}
                            className='group relative h-auto min-h-16 w-full max-w-48 rounded-xl hover:cursor-pointer sm:min-h-48'
                            onClick={() => console.log('onclick-card-tes')}
                          >
                            <CardBody className='relative my-auto flex h-32 w-full flex-col items-center overflow-hidden sm:h-44 sm:px-4 sm:py-6'>
                              <span className='absolute left-0 top-0 z-[1] mr-auto rounded-full border border-blue-400 bg-blue-400 px-2 py-0.5 text-xs text-white shadow sm:top-2'>
                                New
                              </span>
                              <img
                                className='my-auto h-auto max-h-[80px] w-auto rounded-xl duration-300 group-hover:scale-110 sm:h-full sm:max-h-[180px]'
                                src={
                                  item.attributes.thumbnail?.data.attributes.url
                                }
                                alt={
                                  item.attributes.thumbnail?.data.attributes
                                    .caption ?? ''
                                }
                              />
                              <p className='text-center text-sm sm:inline-block'>
                                {item.attributes.name}
                              </p>
                            </CardBody>
                          </Cards>
                        </Link>
                      </div>
                    );
                  })}
                </>
              )}
              {/* </Slider> */}
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ExploreProducts;
