import Link from 'next/link';
import React from 'react';
import {
  FaFacebookF,
  FaInstagram,
  FaPinterest,
  FaTiktok,
  FaYoutube,
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

const links = [
  { href: 'https://support.mrcool.com', text: 'Support' },
  {
    href: 'https://support.mrcool.com/hc/en-us/requests/new',
    text: 'Contact Us',
  },
  { href: '/support/become-reseller', text: 'Become a Dealer' },
  { href: '/locations', text: 'Locations' },
  { href: '/rebates', text: 'Rebate Center' },
  { href: '/support/become-influencer', text: 'Become an Influencer' },
  { href: '/our-story', text: 'Our Story' },
  { href: '/blog', text: 'Blog' },
  { href: '/system-selector', text: 'System Selector' },
  { href: '/warranty-information', text: 'Warranty Information' },
  { href: '/warranty-registration', text: 'Warranty Registration' },
  { href: '/intelectual-property/patents', text: 'Patents' },
  { href: '/intelectual-property/trademarks', text: 'Trademarks' },
  { href: 'https://mrcool.com/portal', text: 'Partner Center' },
  {
    href: 'https://elegant-novelty-497722905c.strapiapp.com/api/sitemap/index.xml',
    text: 'Sitemap',
  },
];

const splitLinksIntoColumns = (
  linksArray: { href: string; text: string }[]
) => [linksArray.slice(0, 6), linksArray.slice(6)];

const Footer = () => {
  const columns = splitLinksIntoColumns(links);
  return (
    <footer className='relative overflow-hidden bg-white'>
      <div className='mx-auto w-full max-w-[90rem] px-5 pt-16 sm:px-6 lg:px-[120px]'>
        <div className='flex flex-col gap-y-16 sm:gap-y-20'>
          <div className='grid grid-cols-1 gap-4 sm:grid-cols-4 sm:gap-9 lg:grid-cols-5 xl:grid-cols-6'>
            <div className='flex sm:col-span-2 lg:col-span-3 xl:col-span-4' />
            {columns.map((column, colIndex) => (
              <div key={colIndex} className='col-span-1 flex'>
                <div className='flex flex-col gap-y-5'>
                  {column.map((link, i) => (
                    <div key={i}>
                      <Link
                        href={{ pathname: link.href }}
                        scroll={true}
                        className='inline-flex gap-x-2 text-[15px] text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600'
                      >
                        {link.text}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className='flex flex-col gap-y-6'>
            <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between'>
              <Link
                href={{ pathname: '/' }}
                scroll={true}
                className='flex-none'
              >
                <img
                  src='/images/mrcool-logo.svg'
                  alt='logo'
                  className='w-full max-w-40'
                />
              </Link>
              <div className='mt-9 flex items-center gap-2 sm:mt-0 sm:justify-center'>
                <div className='grid grid-cols-6 gap-3'>
                  <a
                    className='text-gray inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 hover:text-gray-800'
                    href='https://www.facebook.com/MRCOOLcomfort'
                    target='_blank'
                  >
                    <FaFacebookF className='h-4 w-4 text-inherit' />
                  </a>
                  <a
                    className='text-gray inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 hover:text-gray-800'
                    href='https://www.instagram.com/mrcoolcomfort'
                    target='_blank'
                  >
                    <FaInstagram className='h-4 w-4 text-inherit' />
                  </a>
                  <a
                    className='text-gray inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 hover:text-gray-800'
                    href='https://www.youtube.com/@MrcoolComfort'
                    target='_blank'
                  >
                    <FaYoutube className='h-4 w-4 text-inherit' />
                  </a>
                  <a
                    className='text-gray inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 hover:text-gray-800'
                    href='https://www.tiktok.com/@mrcooldiy'
                    target='_blank'
                  >
                    <FaTiktok className='h-4 w-4 text-inherit' />
                  </a>
                  <a
                    className='text-gray inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 hover:text-gray-800'
                    href='https://twitter.com/mrcoolcomfort'
                    target='_blank'
                  >
                    <FaXTwitter className='h-4 w-4 text-inherit' />
                  </a>
                  <a
                    className='text-gray inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 hover:text-gray-800'
                    href='https://www.pinterest.com/mrcoolcomfort'
                    target='_blank'
                  >
                    <FaPinterest className='h-4 w-4 text-inherit' />
                  </a>
                </div>
              </div>
            </div>
            <div className='pb-12  pt-6'>
              <div className='flex flex-col-reverse sm:flex-row sm:items-center sm:justify-between'>
                <div className='text-sm text-gray-600 dark:text-gray-400'>
                  Copyright © 2024 MRCOOL®. All Rights Reserved.
                </div>

                <div className='mb-5 flex flex-col gap-4 text-sm sm:gap-8 lg:mb-0 lg:flex-row'>
                  <Link
                    className='text-sm text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 sm:inline-flex'
                    href={{ pathname: '/privacy-policy' }}
                  >
                    Privacy Policy
                  </Link>
                  <Link
                    className='text-sm text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 sm:inline-flex'
                    href={{ pathname: '/terms-and-conditions' }}
                  >
                    Terms & Conditions
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
